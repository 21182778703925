import { Logo } from "~/components/logo.tsx";
import { Spinner } from "@radix-ui/themes";

export function PendingLayout() {
  return (
    <div className="flex flex-col gap-5 items-center justify-center h-dvh">
      <Logo />
      <Spinner />
    </div>
  );
}
