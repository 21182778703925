import { ComponentProps, ElementRef, forwardRef, ReactNode } from "react";
import { Text } from "@radix-ui/themes";
import { type FieldError } from "react-hook-form";
import { cn } from "@packages/utils/cn.ts";

const FormLabel = forwardRef<
  ElementRef<typeof Text>,
  ComponentProps<typeof Text>
>(function FormLabel(props, ref) {
  return <Text weight="medium" size="2" as="label" ref={ref} {...props} />;
});

function FormField({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={cn("space-y-2", className)}>{children}</div>;
}

interface FormErrorProps extends FieldError {}
const FormError = ({ message }: FormErrorProps) => {
  return (
    <Text as="div" color="red" size="1">
      {message}
    </Text>
  );
};

export const Form = {
  Label: FormLabel,
  Error: FormError,
  Field: FormField,
};
