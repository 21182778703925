import Bell from "@packages/ui/icons/line/alerts/bell.tsx";
import ChevronRight from "@packages/ui/icons/line/arrows/chevron-right.tsx";
import Code from "@packages/ui/icons/line/arrows/code.tsx";
import Gear from "@packages/ui/icons/line/basic/gear.tsx";
import Home2 from "@packages/ui/icons/line/basic/home-2.tsx";
import Plus from "@packages/ui/icons/line/basic/plus.tsx";
import PresentationChart from "@packages/ui/icons/line/basic/presentation-chart.tsx";
import Wallet from "@packages/ui/icons/line/finances/wallet.tsx";
import Users from "@packages/ui/icons/line/users/users.tsx";
import SidebarLeft from "@packages/ui/icons/sidebar-left.tsx";
import SidebarRight from "@packages/ui/icons/sidebar-right.tsx";
import { ScrollArea } from "@packages/ui/scroll-area.tsx";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@packages/ui/tooltip.tsx";
import { cn } from "@packages/utils/cn.ts";
import { platformTitles } from "@packages/utils/enum-maps.ts";
import type { BreadcrumbLinkItem } from "@packages/utils/types.ts";
import { productizeBreadcrumbs } from "@platforms/productize/client/breadcrumbs.ts";
import { productizeSidebarLinks } from "@platforms/productize/client/sidebar-links.tsx";
import { Avatar, HoverCard, Popover, Text } from "@radix-ui/themes";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useWindowSize } from "@uidotdev/usehooks";
import localforage from "localforage";
import {
	type ReactNode,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Link,
	type NavLinkProps,
	Outlet,
	NavLink as RRNavLink,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { toast } from "sonner";
import {
	MainLayoutContent,
	MainLayoutContentPending,
	MainLayoutHeader,
} from "~/components/layouts/main-layout.tsx";
import { Logo } from "~/components/logo.tsx";
import { api } from "~/lib/api.ts";
import { isElementScrollable } from "~/lib/browser.ts";
import {
	sidebarConfigQueryOptions,
	useSidebarConfigMutations,
} from "~/lib/config.ts";
import {
	BUSINESS_ORG_ID_KEY,
	BUSINESS_SUBDOMAIN_KEY,
} from "~/lib/constants.ts";

export default function BusinessLayout() {
	const navigate = useNavigate();
	const location = useLocation();
	const utils = api.useUtils();
	const [businessPopoverOpen, setBusinessPopoverOpen] = useState(false);
	const params = useParams();
	const breadcrumbItems = useMemo<BreadcrumbLinkItem[]>(() => {
		// remove / on end of pathname, so we can match it with the switch statement
		const pathname = location.pathname.replace(/\/$/, "");

		switch (pathname) {
			case `/biz/${params.subdomain}`:
				return [{ title: "Dashboard" }];
			case `/biz/${params.subdomain}/finances`:
				return [{ title: "Finances" }];
			case `/biz/${params.subdomain}/analytics`:
				return [{ title: "Analytics" }];
			case `/biz/${params.subdomain}/customers`:
				return [{ title: "Customers" }];
			case `/biz/${params.subdomain}/settings`:
				return [{ title: "Settings" }];
			case `/biz/${params.subdomain}/settings/pricing`:
				return [
					{ title: "Settings", to: `/biz/${params.subdomain}/settings` },
					{ title: "Pricing" },
				];
			case `/biz/${params.subdomain}/settings/payouts`:
				return [
					{ title: "Settings", to: `/biz/${params.subdomain}/settings` },
					{ title: "Payouts" },
				];
			case `/biz/${params.subdomain}/settings/staff`:
				return [
					{ title: "Settings", to: `/biz/${params.subdomain}/settings` },
					{ title: "Staff Members" },
				];
			default:
				if (pathname.startsWith(`/biz/${params.subdomain}/productize`)) {
					return productizeBreadcrumbs(pathname, params.subdomain ?? "unset");
				}
				return [];
		}
	}, [params.subdomain, location.pathname]);
	const [business] = api.management.business.retrieve.useSuspenseQuery({
		subdomain: params.subdomain,
	});
	const [businesses] = api.management.business.list.useSuspenseQuery();
	const sidebarConfig = useSuspenseQuery(sidebarConfigQueryOptions());
	const { setSidebarState } = useSidebarConfigMutations();
	const windowSize = useWindowSize();
	const sidebarScrollRegionRef = useRef<HTMLDivElement>(null);
	const [sidebarIsScrollable, setSidebarIsScrollable] =
		useState<boolean>(false);

	const toggleSidebarState = () => {
		setSidebarState.mutate(
			sidebarConfig.data.state === "collapsed" ? "expanded" : "collapsed",
		);
	};

	const handleScrollRegionChanges = useCallback(
		() =>
			setSidebarIsScrollable(
				isElementScrollable(sidebarScrollRegionRef.current),
			),
		[],
	);

	const navigateToBusiness = (subdomain: string) => async () => {
		setBusinessPopoverOpen(false);

		toast.info("Switching businesses...");

		await Promise.all([
			localforage.removeItem(BUSINESS_ORG_ID_KEY),
			localforage.removeItem(BUSINESS_SUBDOMAIN_KEY),
			utils.management.business.invalidate(),
		]);

		// NOTE: timeout was added to wait on the popover to close. It's a visual thing
		setTimeout(() => navigate(`/biz/${subdomain}`), 150);
	};

	useEffect(() => {
		handleScrollRegionChanges();
	}, [handleScrollRegionChanges]);

	useEffect(() => {
		if (windowSize.height) handleScrollRegionChanges();
	}, [windowSize.height, handleScrollRegionChanges]);

	return (
		<div className="h-dvh overflow-hidden flex">
			<nav
				className={cn(
					"flex flex-col relative",
					sidebarConfig.data.state === "collapsed" ? "w-[100px]" : "w-[300px]",
				)}
			>
				<div
					className={cn(
						"w-full flex items-center justify-between px-5 relative",
						sidebarConfig.data.state === "collapsed"
							? "flex-col"
							: "flex-row h-[80px]",
					)}
				>
					{sidebarIsScrollable && sidebarConfig.data.state === "expanded" && (
						<div className="h-px bg-surface !z-[99] absolute -bottom-px left-0 right-0 w-[320px] border-t border-black/10" />
					)}
					<div
						className={cn(
							sidebarConfig.data.state === "collapsed" &&
								"h-[80px] flex items-center",
						)}
					>
						<Logo />
					</div>
					<div
						className={cn(
							"flex items-center gap-2.5 relative",
							sidebarConfig.data.state === "collapsed"
								? "flex-col pb-2.5 gap-[5px]"
								: "flex-row",
						)}
					>
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<button
									type="button"
									className="h-10 w-10 rounded-full bg-transparent border-none text-black/50 hover:bg-black/5 dark:text-white/50 dark:hover:bg-white/5 inline-flex items-center justify-center"
								>
									<Bell width={18} height={18} strokeWidth={2} />
								</button>
							</TooltipTrigger>
							<TooltipContent
								sideOffset={5}
								side={
									sidebarConfig.data.state === "collapsed" ? "right" : "bottom"
								}
							>
								Alerts
							</TooltipContent>
						</Tooltip>
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<button
									type="button"
									onClick={toggleSidebarState}
									className="h-10 w-10 rounded-full bg-transparent border-none text-black/50 hover:bg-black/5 dark:text-white/50 dark:hover:bg-white/5 inline-flex items-center justify-center"
								>
									{sidebarConfig.data.state === "collapsed" ? (
										<SidebarRight width={18} height={18} strokeWidth={1.5} />
									) : (
										<SidebarLeft width={18} height={18} strokeWidth={1.5} />
									)}
								</button>
							</TooltipTrigger>
							<TooltipContent
								sideOffset={5}
								side={
									sidebarConfig.data.state === "collapsed" ? "right" : "bottom"
								}
							>
								{sidebarConfig.data.state === "collapsed" ? "Expand" : "Shrink"}{" "}
								Sidebar
							</TooltipContent>
						</Tooltip>
					</div>
				</div>
				<ScrollArea
					ref={sidebarScrollRegionRef}
					className={cn(
						"relative flex-1 border-transparent bg-background",
						sidebarConfig.data.state === "collapsed"
							? "text-center border-y"
							: "px-5",
					)}
				>
					<div className="space-y-2.5 pb-2.5">
						<div>
							<div className="p-2.5 text-xs font-medium text-black/50 dark:text-white/50">
								Business
							</div>
							<ul className="space-y-[5px]">
								<li>
									<NavLink
										end
										to={`/biz/${params.subdomain}`}
										Icon={<Home2 strokeWidth={2} width={18} height={18} />}
									>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/biz/${params.subdomain}/finances`}
										Icon={<Wallet strokeWidth={2} width={18} height={18} />}
									>
										Finances
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/biz/${params.subdomain}/analytics`}
										Icon={
											<PresentationChart
												strokeWidth={2}
												width={18}
												height={18}
											/>
										}
									>
										Analytics
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/biz/${params.subdomain}/customers`}
										Icon={<Users strokeWidth={2} width={18} height={18} />}
									>
										Customers
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/biz/${params.subdomain}/settings`}
										Icon={<Gear strokeWidth={2} width={18} height={18} />}
										subLinks={[
											{
												end: true,
												to: `/biz/${params.subdomain}/settings`,
												children: "General",
											},
											{
												to: `/biz/${params.subdomain}/settings/pricing`,
												children: "Pricing",
											},
											{
												to: `/biz/${params.subdomain}/settings/payouts`,
												children: "Payouts",
											},
											{
												to: `/biz/${params.subdomain}/settings/staff`,
												children: "Staff Members",
											},
										]}
									>
										Settings
									</NavLink>
								</li>
							</ul>
						</div>
						<div>
							<div className="p-2.5 text-xs font-medium text-black/50 dark:text-white/50">
								{platformTitles[business.platformType]}
							</div>
							<ul className="space-y-[5px]">
								{productizeSidebarLinks(params.subdomain ?? "unset").map(
									(link) => (
										<li key={link.href}>
											<NavLink to={link.href} Icon={link.Icon}>
												{link.title}
											</NavLink>
										</li>
									),
								)}
							</ul>
						</div>
					</div>
				</ScrollArea>
				<div className="p-5 relative">
					{sidebarIsScrollable && sidebarConfig.data.state === "expanded" && (
						<div className="h-px bg-surface !z-[1000] absolute -top-px left-0 right-0 w-[300px] border-t border-black/10 " />
					)}
					<Popover.Root
						open={businessPopoverOpen}
						onOpenChange={setBusinessPopoverOpen}
					>
						<Popover.Trigger>
							<button
								type="button"
								className={cn(
									"border border-black/10 dark:border-white/10 h-[50px] bg-white/30 dark:bg-black/30 w-full rounded-[10px] shadow-float dark:shadow-none flex items-center justify-between pl-[9px] pr-[15px]",
									sidebarConfig.data.state === "collapsed" &&
										"h-10 px-0 justify-center border-0 bg-transparent shadow-none",
								)}
							>
								<div className="flex items-center justify-start gap-2.5">
									<div
										className={cn(
											"w-8 h-8 rounded-full bg-black/10 dark:bg-white/10 border border-black/10",
											sidebarConfig.data.state === "collapsed" && "h-10 w-10",
										)}
									/>
									{sidebarConfig.data.state === "expanded" ? (
										<div className="text-left">
											<div className="font-semibold text-sm text-black/70 dark:text-white/70 leading-[18px]">
												{business.name}
											</div>
											<div className="font-semibold text-xs text-black/50 dark:text-white/50 leading-[12px]">
												{platformTitles[business.platformType]}
											</div>
										</div>
									) : undefined}
								</div>
								{sidebarConfig.data.state === "expanded" ? (
									<Code
										width={18}
										height={18}
										strokeWidth={2}
										className="text-black/50 dark:text-white/50 rotate-90"
									/>
								) : undefined}
							</button>
						</Popover.Trigger>
						<Popover.Content
							side="top"
							align="start"
							width="260px"
							className="!shadow-float border border-black/10 !rounded-[10px] !p-2"
						>
							{businesses.map((business) => (
								<button
									type="button"
									onClick={navigateToBusiness(business.subdomain)}
									key={business.id}
									className="flex gap-2.5 items-center hover:bg-black/5 p-2 rounded-[6px] group w-full text-left"
								>
									<Avatar
										fallback={business.name[0]}
										src={business.logoUrl ?? undefined}
										size="1"
										radius="full"
									/>
									<Text truncate className="flex-1" weight="medium" size="2">
										{business.name}
									</Text>
									<ChevronRight
										height={18}
										width={18}
										strokeWidth={2}
										className="opacity-0 group-hover:opacity-100"
									/>
								</button>
							))}
							<hr className="my-1" />
							<Link
								to="/new-business"
								className="flex gap-2.5 items-center justify-between hover:bg-black/5 p-2 rounded-[6px]"
							>
								<Text truncate className="flex-1" weight="medium" size="2">
									New Business
								</Text>
								<Plus width={18} height={18} strokeWidth={2} />
							</Link>
						</Popover.Content>
					</Popover.Root>
				</div>
			</nav>
			<div className="flex-1 flex flex-col">
				<Suspense
					fallback={<MainLayoutContentPending breadcrumbs={breadcrumbItems} />}
				>
					<MainLayoutHeader breadcrumbs={breadcrumbItems} />
					<MainLayoutContent>
						<Outlet />
					</MainLayoutContent>
				</Suspense>
			</div>
		</div>
	);
}

function NavLink({
	children,
	Icon,
	subLinks,
	...props
}: NavLinkProps & {
	Icon: ReactNode;
	subLinks?: NavLinkProps[];
}) {
	const [hoverCardOpen, setHoverCardOpen] = useState(false);
	const location = useLocation();
	const sidebarConfig = useSuspenseQuery(sidebarConfigQueryOptions());
	const linkButtonStyles = cn(
		"flex items-center justify-start bg-transparent border border-transparent rounded-[8px] h-[38px] px-2.5 gap-2.5 text-sm font-medium text-black/50 dark:text-white/50 hover:bg-black/5 dark:hover:bg-white/5",
	);
	const linkActiveStyles = cn(
		"!bg-surface !border-black/10 dark:!border-white/10 !font-semibold !shadow-float !text-black dark:!text-white hover:bg-white",
	);
	const isBasePath = props.end
		? location.pathname === props.to
		: typeof props.to === "string"
			? location.pathname.startsWith(props.to)
			: location.pathname.startsWith(props.to.pathname ?? "unset");

	useEffect(() => {
		location.pathname && setHoverCardOpen(false);
	}, [location.pathname]);

	return (
		<div
			className={cn(
				isBasePath &&
					sidebarConfig.data.state === "expanded" &&
					"bg-black/5 dark:bg-white/5 rounded-[10px]",
			)}
		>
			{sidebarConfig.data.state === "expanded" ? (
				<>
					<RRNavLink
						{...props}
						className={({ isActive }) =>
							cn(linkButtonStyles, isActive && linkActiveStyles)
						}
					>
						{({ isActive }) => (
							<>
								<div className={cn(isActive && "text-brand")}>{Icon}</div>
								{children}
							</>
						)}
					</RRNavLink>
					{isBasePath && subLinks && subLinks.length > 0 ? (
						<div className="p-1">
							{subLinks.map((subLink, index) => (
								<NavSubLink {...subLink} key={`${subLink.to}-${index}`} />
							))}
						</div>
					) : undefined}
				</>
			) : subLinks && subLinks.length > 0 ? (
				<div className="relative">
					<HoverCard.Root open={hoverCardOpen} onOpenChange={setHoverCardOpen}>
						<HoverCard.Trigger>
							<div>
								<RRNavLink
									{...props}
									className={({ isActive }) => {
										return cn(
											linkButtonStyles,
											"px-0 justify-center w-10 mx-auto",
											isActive && linkActiveStyles,
										);
									}}
								>
									{({ isActive }) => (
										<div className={cn(isActive && "text-brand")}>{Icon}</div>
									)}
								</RRNavLink>
							</div>
						</HoverCard.Trigger>
						<HoverCard.Content
							side="right"
							align="start"
							className="!shadow-float border border-black/10 dark:border-white/10 !rounded-[10px] !p-1"
						>
							<div className="p-1">
								{subLinks.map((subLink, index) => (
									<NavSubLink
										{...subLink}
										key={`${subLink.to}-${index}`}
										popover
									/>
								))}
							</div>
						</HoverCard.Content>
					</HoverCard.Root>
					<ChevronRight
						height={18}
						width={18}
						strokeWidth={2}
						className="text-black/50 dark:text-white/50 absolute right-1.5 top-1/2 -translate-y-1/2"
					/>
				</div>
			) : (
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<div>
							<RRNavLink
								{...props}
								className={({ isActive }) =>
									cn(
										linkButtonStyles,
										"px-0 justify-center w-10 mx-auto",
										isActive && linkActiveStyles,
									)
								}
							>
								{({ isActive }) => (
									<div className={cn(isActive && "text-brand")}>{Icon}</div>
								)}
							</RRNavLink>
						</div>
					</TooltipTrigger>
					<TooltipContent side="right">{children as ReactNode}</TooltipContent>
				</Tooltip>
			)}
		</div>
	);
}

function NavSubLink(
	props: NavLinkProps & {
		popover?: boolean;
	},
) {
	return (
		<RRNavLink
			{...props}
			className={({ isActive }) =>
				cn(
					"flex rounded-[6px] hover:bg-black/5 dark:hover:bg-white/5 px-2 py-1 border border-transparent text-sm font-medium text-black/50 dark:text-white/50",
					isActive &&
						"bg-surface !border-black/10 dark:!border-white/10 !font-semibold !text-black dark:!text-white hover:bg-surface hover:dark:!bg-surface",
					isActive &&
						props.popover &&
						"bg-black/5 dark:bg-white/5 hover:bg-black/5 dark:hover:!bg-white/5",
				)
			}
		/>
	);
}
