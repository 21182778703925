export const organizationPermissions = [
	"read:biz",
	"update:biz",
	"delete:biz",
	"update:settings:payouts",
	"read:settings:payouts",
	"read:settings:staff",
	"update:settings:staff",
	"delete:settings:staff",
	"read:settings:staff:invite",
	"create:settings:staff:invite",
	"delete:settings:staff:invite",
	"create:settings:pricing",
	"read:settings:pricing",
	"update:settings:pricing",
	"delete:settings:pricing",
] as const;
export type OrganizationPermission = (typeof organizationPermissions)[number];

export const organizationRoles = ["owner", "staff"] as const;
export type OrganizationRole = (typeof organizationRoles)[number];

export const organizationRoleIds: Record<OrganizationRole, string> = {
	owner: "0ztc7uqfefjrq9xts565y",
	staff: "93ax2uik5slbbkf3jj3xx",
} as const;

export const organizationRoleIdTypes: Record<string, string> = {
	[organizationRoleIds.owner]: "owner",
	[organizationRoleIds.staff]: "staff",
} as const;

export const organizationRoleIdNames: Record<string, string> = {
	[organizationRoleIds.owner]: "Owner",
	[organizationRoleIds.staff]: "Staff",
} as const;
