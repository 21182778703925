import type { SVGProps } from "react";
const SvgInboxFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={props.strokeWidth}
      d="M2.5 14h2.787c.626 0 .94 0 1.224.086a2 2 0 0 1 .688.369c.23.188.403.449.75.97l.1.15c.348.521.522.782.751.97a2 2 0 0 0 .689.369c.284.086.597.086 1.223.086h2.575c.626 0 .94 0 1.224-.086a2 2 0 0 0 .688-.369c.23-.189.403-.449.75-.97l.1-.15c.348-.521.522-.782.751-.97.203-.167.437-.292.689-.369.284-.086.597-.086 1.223-.086H21.5M9 7h6m-5 4h4M3.485 8.06l-.616 2.464c-.14.562-.21.843-.267 1.123a12 12 0 0 0 0 4.706c.056.28.126.561.267 1.123v0c.175.7.262 1.05.38 1.35a5 5 0 0 0 4.012 3.133c.32.041.68.041 1.402.041h6.674c.721 0 1.082 0 1.402-.041a5 5 0 0 0 4.011-3.133c.118-.3.206-.65.38-1.35v0c.141-.562.212-.843.268-1.123.31-1.553.31-3.153 0-4.706-.056-.28-.127-.561-.267-1.123l-.616-2.464c-.542-2.167-.813-3.25-1.417-4.057a5 5 0 0 0-2.065-1.613C16.103 2 14.987 2 12.753 2h-1.507c-2.233 0-3.35 0-4.279.39a5 5 0 0 0-2.065 1.613c-.604.807-.875 1.89-1.417 4.057Z"
    />
  </svg>
);
export default SvgInboxFilled;
