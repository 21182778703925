import { Route } from "react-router-dom";

export const ProductizeRoutes = (
	<Route path="productize">
		<Route index path="" element={<div>productize!!!!!!!!</div>} />
		<Route path="requests" element={<div>Requests</div>} />
		<Route path="deliverables" element={<div>Deliverables</div>} />
		<Route path="messages" element={<div>Messages</div>} />
		<Route path="invoices" element={<div>Invoices</div>} />
		<Route path="settings" element={<div>Settings</div>} />
	</Route>
);
