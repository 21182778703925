import type { BreadcrumbLinkItem } from "@packages/utils/types.ts";

export function productizeBreadcrumbs(
	pathname: string,
	subdomain: string,
): BreadcrumbLinkItem[] {
	switch (pathname) {
		case `/biz/${subdomain}/productize/requests`:
			return [{ title: "Productize" }, { title: "Requests" }];
		case `/biz/${subdomain}/productize/deliverables`:
			return [{ title: "Productize" }, { title: "Deliverables" }];
		case `/biz/${subdomain}/productize/messages`:
			return [{ title: "Productize" }, { title: "Messages" }];
		case `/biz/${subdomain}/productize/invoices`:
			return [{ title: "Productize" }, { title: "Invoices" }];
		case `/biz/${subdomain}/productize/settings`:
			return [{ title: "Productize" }, { title: "Settings" }];
		default:
			return [];
	}
}
