export const Logo = () => {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83077 3.41538C6.83077 1.52912 5.30165 0 3.41538 0C1.52912 0 0 1.52912 0 3.41539V20.0308C0 25.5366 4.46338 30 9.96923 30C15.4751 30 19.9385 25.5366 19.9385 20.0308C19.9385 14.5249 15.4751 10.0615 9.96923 10.0615C8.39755 10.0615 6.83077 8.94496 6.83077 7.37328V3.41538Z"
        className="fill-brand"
      />
    </svg>
  );
};
