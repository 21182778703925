import type { SVGProps } from "react";
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M2 4v13.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 22 5.12 22 6.8 22h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 19.72 22 18.88 22 17.2v-6.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 6 18.88 6 17.2 6H12m10 5h-.5a3 3 0 0 0-3 3v0a3 3 0 0 0 3 3h.5M17 6l-1.08-1.44c-.704-.939-1.056-1.408-1.502-1.746a4 4 0 0 0-1.32-.66C12.56 2 11.974 2 10.8 2H4a2 2 0 0 0-2 2v0a2 2 0 0 0 2 2z"
    />
  </svg>
);
export default SvgWallet;
