import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import * as localforage from "localforage";
import { z } from "zod";

const CONFIG_KEY = "config";
const SIDEBAR_KEY = "sidebar";

export const SidebarConfig = z.object({
  state: z.enum(["expanded", "collapsed"]),
});
export type SidebarConfig = z.infer<typeof SidebarConfig>;

const getSidebarConfig = async () => {
  const parsedConfig = SidebarConfig.safeParse(
    await localforage.getItem<SidebarConfig>(SIDEBAR_KEY),
  );

  if (parsedConfig.success) return parsedConfig.data;

  // Set the correct config here
  const config: SidebarConfig = {
    state: "expanded",
  };

  await localforage.setItem(SIDEBAR_KEY, config);

  return config;
};

export const sidebarConfigQueryOptions = () =>
  queryOptions({
    queryKey: [CONFIG_KEY, SIDEBAR_KEY],
    queryFn: getSidebarConfig,
  });

export const useSidebarConfigMutations = () => {
  const queryClient = useQueryClient();
  const setSidebarState = useMutation({
    mutationFn: async (state: SidebarConfig["state"]) => {
      const config: SidebarConfig = {
        ...(await getSidebarConfig()),
        state,
      };

      await localforage.setItem(SIDEBAR_KEY, config);

      return config;
    },
    onSuccess: (config) => {
      queryClient.setQueryData([CONFIG_KEY, SIDEBAR_KEY], config);
    },
  });

  return {
    setSidebarState,
  };
};
