import type { SVGProps } from "react";
const SvgFolderCompressed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M18 6.5V7a2 2 0 1 1-4 0V6m2 7v1m0 3v1M6.8 2h1.517c.801 0 1.202 0 1.564.091a3 3 0 0 1 1.538.95c.243.284.422.642.781 1.359v0c.239.478.359.717.52.906a2 2 0 0 0 1.026.633c.241.061.508.061 1.043.061h.811c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C22 9.04 22 10.16 22 12.4v3.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C18.96 22 17.84 22 15.6 22H8.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 18.96 2 17.84 2 15.6V6.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C4.28 2 5.12 2 6.8 2"
    />
  </svg>
);
export default SvgFolderCompressed;
