import type { SVGProps } from "react";
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M8 19.546C8.659 21.027 10.378 22 12 22s3.341-.973 4-2.454m2-8.356V8a6 6 0 0 0-6-6v0a6 6 0 0 0-6 6v3.19c0 .834-.354 1.629-.974 2.186l-1.107.997c-1.323 1.19-.901 3.36.771 3.969l.211.077a20.77 20.77 0 0 0 14.198 0l.21-.077c1.673-.608 2.095-2.778.772-3.969l-1.107-.997A2.94 2.94 0 0 1 18 11.19"
    />
  </svg>
);
export default SvgBell;
