import LogtoClient, { type LogtoConfig } from "@logto/browser";
import { organizationPermissions } from "@packages/utils/logto.ts";
import { queryOptions } from "@tanstack/react-query";
import { timeout } from "~/lib/browser.ts";
import { BUSINESS_API_IDENTIFIER } from "./constants.ts";

export const logtoConfig: LogtoConfig = {
	endpoint: "https://accounts.bordsy.com/",
	appId: "zj765w1inqhjdi7soahzt",
	resources: [BUSINESS_API_IDENTIFIER],
	scopes: [...organizationPermissions, "urn:logto:scope:organizations"],
};

export const logto = new LogtoClient(logtoConfig);

export const isAuthedQueryOptions = (options: { enabled?: boolean }) =>
	queryOptions({
		queryKey: ["is-authed"],
		queryFn: async () => {
			const authed = await logto.isAuthenticated();

			if (!authed) {
				await logto.signIn(`${window.location.origin}/callback`);

				// Small buffer to allow for the redirection to occur
				await timeout(30_000);

				return false;
			}

			return true;
		},
		enabled: options.enabled,
	});
