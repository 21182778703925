interface RequestOptions extends RequestInit {
  dedupe?: boolean;
}

interface CacheEntry {
  promise: Promise<Response>;
  timestamp: number;
  clients: Set<string>;
}

function createId(): string {
  const timestamp = Date.now().toString(36);
  const randomPart = Math.random().toString(36).substr(2, 5);
  return `${timestamp}-${randomPart}`;
}

class DedupingNetworkClient {
  private cache: Map<string, CacheEntry> = new Map();
  private cacheTTL: number = 5000; // 5 seconds

  async fetch(url: string, options: RequestOptions = {}): Promise<Response> {
    const cacheKey = this.getCacheKey(url, options);
    const clientId = createId();

    if (options.dedupe !== false) {
      const cachedEntry = this.cache.get(cacheKey);

      if (cachedEntry) {
        cachedEntry.clients.add(clientId);
        return cachedEntry.promise.then((response) => response.clone());
      }
    }

    const promise = this.executeFetch(url, options);

    if (options.dedupe !== false) {
      this.cache.set(cacheKey, {
        promise,
        timestamp: Date.now(),
        clients: new Set([clientId]),
      });

      promise.finally(() => {
        setTimeout(() => {
          const entry = this.cache.get(cacheKey);
          if (entry) {
            entry.clients.delete(clientId);
            if (entry.clients.size === 0) {
              this.cache.delete(cacheKey);
            }
          }
        }, this.cacheTTL);
      });
    }

    return promise;
  }

  private async executeFetch(
    url: string,
    options: RequestOptions
  ): Promise<Response> {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.clone();
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  }

  private getCacheKey(url: string, options: RequestOptions): string {
    const { method = "GET", headers = {}, body } = options;
    return `${method}:${url}:${JSON.stringify(headers)}:${body}`;
  }
}

export const dedupedClient = new DedupingNetworkClient();
