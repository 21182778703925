import type { SVGProps } from "react";
const SvgMessanger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="m7.255 12.964 3.884-2.913 1.644 2.448 3.962-2.971M2 11.126c0 2.992 1.578 5.626 3.999 7.287C5.998 19.367 6 20.652 6 22l3.923-1.948c.671.129 1.365.2 2.077.2 5.502 0 10-4.064 10-9.126C22 6.063 17.502 2 12 2S2 6.063 2 11.126"
    />
  </svg>
);
export default SvgMessanger;
