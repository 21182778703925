export const WEBSITE_URL = "https://www.bordsy.com";
export const PROD_BASE_URL = "https://business.bordsy.com";
export const DEV_BASE_URL = "https://business.bordsy.local";

/**
 * The base URL for the business application
 */
export const BUSINESS_BASE_URL = import.meta.env.DEV
  ? DEV_BASE_URL
  : PROD_BASE_URL;

/**
 * The base URL for the business API (used for authentication)
 */
export const BUSINESS_API_IDENTIFIER = `${PROD_BASE_URL}/api`;

/**
 * Key for storing the selected organization ID in local storage
 */
export const BUSINESS_ORG_ID_KEY = "selected-org-id";

/**
 * Key for storing the selected business subdomain in local storage
 */
export const BUSINESS_SUBDOMAIN_KEY = "selected-business-subdomain";
