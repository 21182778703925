import type { SVGProps } from "react";
const SvgInvoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M18 12V2l-2.713 1.206c-.611.271-.917.407-1.226.441a2 2 0 0 1-.997-.146c-.287-.12-.541-.338-1.05-.774v0c-.674-.578-1.011-.867-1.384-.991a2 2 0 0 0-1.26 0c-.373.124-.71.413-1.385.991v0c-.508.436-.762.653-1.049.774a2 2 0 0 1-.997.146c-.31-.034-.615-.17-1.226-.441L2 2v13.6c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C5.04 22 6.16 22 8.4 22H20m-2-10h.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C22 13.52 22 14.08 22 15.2V20a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2zm-6-2h-2m0 0h-.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m0-6V9m0 7H8m2 0v1"
    />
  </svg>
);
export default SvgInvoice;
