import { organizationRoles } from "@packages/utils/logto.ts";
import { z } from "zod";
import { isValidSubdomain } from "../utils.ts";

export const platformTypes = ["productize"] as const;
export type PlatformTypes = (typeof platformTypes)[number];

export const priceDeactivationActions = [
	"grandfather",
	"update",
	"cancel",
] as const;
export type PriceDeactivationActions =
	(typeof priceDeactivationActions)[number];

export const SubdomainCheckSchema = z
	.string()
	.trim()
	.min(3)
	.max(150)
	.refine(isValidSubdomain);
export type SubdomainCheckSchema = z.infer<typeof SubdomainCheckSchema>;

export const CreateBusinessSchema = z.object({
	name: z.string().trim().min(3).max(150),
	description: z.string().trim().max(1000).optional(),
	email: z.string().email(),
	platformType: z.enum(platformTypes),
	subdomain: SubdomainCheckSchema,
});
export type CreateBusinessSchema = z.infer<typeof CreateBusinessSchema>;

export const UpdateBusinessSchema = z.object({
	businessId: z.string(),
	name: z.string().trim().min(3).max(150).optional(),
	description: z.string().trim().max(1000).optional(),
	email: z.string().email().optional(),
	subdomain: SubdomainCheckSchema.optional(),
});
export type UpdateBusinessSchema = z.infer<typeof UpdateBusinessSchema>;

export const CreateStaffInviteSchema = z.object({
	email: z.string().email(),
	roleType: z.enum(organizationRoles),
});
export type CreateStaffInviteSchema = z.infer<typeof CreateStaffInviteSchema>;

export const ListStaffMembersSchema = z.object({
	page: z.coerce.number().min(0).optional(),
	query: z.coerce.string().optional(),
});
export type ListStaffMembersSchema = z.infer<typeof ListStaffMembersSchema>;

export const UpdateStaffSchema = z.object({
	userId: z.string(),
	roleType: z.enum(organizationRoles),
});
export type UpdateStaffSchema = z.infer<typeof UpdateStaffSchema>;

export const ListPricesSchema = z.object({
	page: z.coerce.number().min(0).optional(),
	query: z.coerce.string().optional(),
});
export type ListPricesSchema = z.infer<typeof ListPricesSchema>;

export const RetrievePriceSchema = z.object({
	priceId: z.string(),
});
export type RetrievePriceSchema = z.infer<typeof RetrievePriceSchema>;

export const CreatePriceSchema = z
	.object({
		name: z.string().trim().min(3).max(150),
		description: z.string().trim().max(1000).optional(),
		amount: z.coerce.number().min(0),
		currency: z.enum(["usd"]),
		interval: z.enum(["day", "week", "month", "year"]).optional(),
		intervalCount: z.coerce.number().int().min(1).max(1095).optional(),
	})
	.refine(
		(data) => {
			if (typeof data.interval === "undefined") {
				return true;
			}

			if (!data.intervalCount) {
				return false;
			}

			// Max of 3 years
			if (data.interval === "year" && data.intervalCount > 3) {
				return false;
			}

			if (data.interval === "month" && data.intervalCount > 36) {
				return false;
			}

			if (data.interval === "week" && data.intervalCount > 156) {
				return false;
			}

			if (data.interval === "day" && data.intervalCount > 1095) {
				return false;
			}

			return true;
		},
		{
			message: "Interval count is too high",
			path: ["intervalCount"],
		},
	);
export type CreatePriceSchema = z.infer<typeof CreatePriceSchema>;

export const UpdatePriceSchema = z
	.object({
		priceId: z.string(),
		name: z.string().trim().min(3).max(150),
		description: z.string().trim().max(1000).optional(),
		amount: z.coerce.number().min(0),
		currency: z.enum(["usd"]),
		interval: z.enum(["day", "week", "month", "year"]).optional(),
		intervalCount: z.coerce.number().int().min(1).max(1095).optional(),
		priceDeactivationActions: z.enum(priceDeactivationActions).optional(),
		prorate: z.boolean().optional(),
	})
	.refine(
		(data) => {
			if (typeof data.interval === "undefined") {
				return true;
			}

			if (!data.intervalCount) {
				return false;
			}

			// Max of 3 years
			if (data.interval === "year" && data.intervalCount > 3) {
				return false;
			}

			if (data.interval === "month" && data.intervalCount > 36) {
				return false;
			}

			if (data.interval === "week" && data.intervalCount > 156) {
				return false;
			}

			if (data.interval === "day" && data.intervalCount > 1095) {
				return false;
			}

			return true;
		},
		{
			message: "Interval count is too high",
			path: ["intervalCount"],
		},
	);

export type UpdatePriceSchema = z.infer<typeof UpdatePriceSchema>;

export const DeletePriceSchema = z.object({
	priceId: z.string(),
	prorate: z.boolean().optional(),
});
export type DeletePriceSchema = z.infer<typeof DeletePriceSchema>;
