import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@packages/ui/form.tsx";
import Info from "@packages/ui/icons/line/alerts/info.tsx";
import { ProfileUpdateSchema } from "@packages/validation/business/profile.ts";
import { Button, Callout, Heading, Text, TextField } from "@radix-ui/themes";
import { useForm } from "react-hook-form";
import { SecondaryLayout } from "~/components/layouts/secondary-layout.tsx";
import { api } from "~/lib/api.ts";

export default function ProfilePage() {
	const [profile] = api.management.profile.retrieve.useSuspenseQuery();
	const profileNotSetup = !profile.firstName || !profile.lastName;
	const updateProfile = api.management.profile.update.useMutation();
	const form = useForm<ProfileUpdateSchema>({
		resolver: zodResolver(ProfileUpdateSchema),
		defaultValues: {
			firstName: profile.firstName ?? "",
			lastName: profile.lastName ?? "",
			dateOfBirth: profile.dateOfBirth ?? undefined,
		},
	});

	const handleSubmit = form.handleSubmit((data) => updateProfile.mutate(data));

	return (
		<SecondaryLayout>
			<form onSubmit={handleSubmit}>
				<div className="mb-6">
					<Heading>Your Profile</Heading>
					<Text as="p" color="gray">
						This is who you are... and you&apos;re the best! Fill out the fields
						below to accurately describe yourself.
					</Text>
				</div>
				{profileNotSetup ? (
					<Callout.Root color="blue" className="mb-6">
						<Callout.Icon>
							<Info height={18} width={18} strokeWidth={2} />
						</Callout.Icon>
						<Callout.Text>
							Your profile hasn&apos;t been completely setup yet. Fill in your
							details below to finish up!
						</Callout.Text>
					</Callout.Root>
				) : undefined}
				<div className="mb-8 space-y-4">
					<Form.Field>
						<Form.Label htmlFor="firstName">First Name</Form.Label>
						<TextField.Root
							type="text"
							placeholder="John"
							id="firstName"
							{...form.register("firstName")}
						/>
						{form.formState.errors.firstName ? (
							<Form.Error {...form.formState.errors.firstName} />
						) : null}
					</Form.Field>
					<Form.Field>
						<Form.Label htmlFor="lastName">Last Name</Form.Label>
						<TextField.Root
							type="text"
							placeholder="Doe"
							id="lastName"
							{...form.register("lastName")}
						/>
						{form.formState.errors.lastName ? (
							<Form.Error {...form.formState.errors.lastName} />
						) : null}
					</Form.Field>
					<Form.Field>
						<Form.Label htmlFor="dateOfBirth">Date of Birth</Form.Label>
						<TextField.Root
							type="date"
							placeholder="Select a date"
							id="dateOfBirth"
							{...form.register("dateOfBirth")}
						/>
						{form.formState.errors.dateOfBirth ? (
							<Form.Error {...form.formState.errors.dateOfBirth} />
						) : null}
					</Form.Field>
				</div>
				<Button type="submit" loading={updateProfile.isPending}>
					Save Changes
				</Button>
			</form>
		</SecondaryLayout>
	);
}
