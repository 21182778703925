import { type LoaderFunction, redirect } from "react-router-dom";
import { PendingLayout } from "~/components/layouts/pending-layout.tsx";
import { logto } from "~/lib/logto.ts";

export const loader: LoaderFunction = async () => {
	await logto.handleSignInCallback(window.location.href);

	return redirect("/");
};

export default function CallbackComponent() {
	return <PendingLayout />;
}
