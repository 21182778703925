import Gear from "@packages/ui/icons/line/basic/gear.tsx";
import InboxFilled from "@packages/ui/icons/line/basic/inbox-filled.tsx";
import Invoice from "@packages/ui/icons/line/basic/invoice.tsx";
import Messenger from "@packages/ui/icons/line/brands/messenger.tsx";
import FolderCompressed from "@packages/ui/icons/line/files/folder-compressed.tsx";
import type { SidebarLinks } from "@packages/utils/types.ts";

export function productizeSidebarLinks(subdomain: string): SidebarLinks {
	return [
		{
			title: "Requests",
			Icon: <InboxFilled strokeWidth={2} width={18} height={18} />,
			href: `/biz/${subdomain}/productize/requests`,
		},
		{
			title: "Deliverables",
			Icon: <FolderCompressed strokeWidth={2} width={18} height={18} />,
			href: `/biz/${subdomain}/productize/deliverables`,
		},
		{
			title: "Messages",
			Icon: <Messenger strokeWidth={2} width={18} height={18} />,
			href: `/biz/${subdomain}/productize/messages`,
		},
		{
			title: "Invoices",
			Icon: <Invoice strokeWidth={2} width={18} height={18} />,
			href: `/biz/${subdomain}/productize/invoices`,
		},
		{
			title: "Platform Settings",
			Icon: <Gear strokeWidth={2} width={18} height={18} />,
			href: `/biz/${subdomain}/productize/settings`,
		},
	];
}
