import { ProductizeRoutes } from "@platforms/productize/client/routes.tsx";
import { useQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import { PendingLayout } from "~/components/layouts/pending-layout.tsx";
import { isAuthedQueryOptions } from "~/lib/logto.ts";
import HomePage from "~/routes/_index.tsx";
import BusinessLayout from "~/routes/biz/$subdomain/_layout.tsx";
import CallbackComponent, {
	loader as callbackLoader,
} from "~/routes/callback.tsx";
import NewBusinessPage from "~/routes/new-business.tsx";
import ProfilePage from "~/routes/profile.tsx";

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			{/* App Routes */}
			<Route index path="/" element={<HomePage />} />
			<Route path="biz/:subdomain" element={<BusinessLayout />}>
				<Route
					index
					path=""
					lazy={() => import("~/routes/biz/$subdomain/_index")}
				/>
				<Route
					path="customers"
					lazy={() => import("~/routes/biz/$subdomain/customers")}
				/>
				<Route
					path="finances"
					lazy={() => import("~/routes/biz/$subdomain/finances")}
				/>
				<Route
					path="analytics"
					lazy={() => import("~/routes/biz/$subdomain/analytics")}
				/>
				<Route path="settings">
					<Route
						index
						path=""
						lazy={() => import("~/routes/biz/$subdomain/settings/_index")}
					/>
					<Route
						path="payouts"
						lazy={() => import("~/routes/biz/$subdomain/settings/payouts")}
					/>
					<Route
						path="pricing"
						lazy={() => import("~/routes/biz/$subdomain/settings/pricing")}
					/>
					<Route
						path="staff"
						lazy={() => import("~/routes/biz/$subdomain/settings/staff")}
					/>
				</Route>
				{/* Platform Routes */}
				{ProductizeRoutes}

				{/* Catch All */}
				<Route path="*" element={<div>Not Found - fill me in biz</div>} />
			</Route>
			<Route path="/new-business" element={<NewBusinessPage />} />
			<Route path="/profile" element={<ProfilePage />} />
			<Route
				path="/callback"
				element={<CallbackComponent />}
				loader={callbackLoader}
			/>

			{/* Catch All */}
			<Route path="*" element={<div>Not Found - fill me in root</div>} />
		</>,
	),
);

function App() {
	const isAuthed = useQuery(
		isAuthedQueryOptions({
			enabled: window.location.pathname !== "/callback",
		}),
	);

	if (isAuthed.isLoading || isAuthed.data === false) {
		return <PendingLayout />;
	}

	return (
		<>
			<Suspense fallback={<PendingLayout />}>
				<RouterProvider router={router} />{" "}
			</Suspense>
		</>
	);
}

export default App;
