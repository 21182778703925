import { ScrollArea } from "@packages/ui/scroll-area.tsx";
import { Button, Container, Flex } from "@radix-ui/themes";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import { WEBSITE_URL } from "~/lib/constants.ts";
import { logto } from "~/lib/logto.ts";
import { Logo } from "../logo.tsx";

export function SecondaryLayout({ children }: { children: ReactNode }) {
	return (
		<ScrollArea className="h-dvh">
			<Container px="5">
				<Flex justify="between" align="center" py="8">
					<Link to="/">
						<Logo />
					</Link>
					<Button onClick={() => logto.signOut(WEBSITE_URL)} variant="soft">
						Logout
					</Button>
				</Flex>
			</Container>
			<Container pb="8" px="5" asChild>
				<main>{children}</main>
			</Container>
		</ScrollArea>
	);
}
