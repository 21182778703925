import type { SVGProps } from "react";
const SvgPresentationChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M3 2h18M3 2v7.6c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C6.04 16 7.16 16 9.4 16h5.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C21 12.96 21 11.84 21 9.6V2M3 2H1.5M21 2h1.5M8 9v2m4-4v4m4-3v3m-4 5v3m0 0-5 3m5-3 5 3m-5-3v3"
    />
  </svg>
);
export default SvgPresentationChart;
