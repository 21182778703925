import type { SVGProps } from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M15 10a4 4 0 0 0 0-8m2 20h2.8a3.2 3.2 0 0 0 3.2-3.2v0a4.8 4.8 0 0 0-4.8-4.8H17m-5-8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M4.2 22h7.6a3.2 3.2 0 0 0 3.2-3.2v0a4.8 4.8 0 0 0-4.8-4.8H5.8A4.8 4.8 0 0 0 1 18.8v0A3.2 3.2 0 0 0 4.2 22"
    />
  </svg>
);
export default SvgUsers;
