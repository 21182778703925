import type { SVGProps } from "react";
const SvgGear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M12 4c-.697 0-1.387-.262-1.814-.813l-.452-.583a2 2 0 0 0-2.58-.507l-1.307.754a2 2 0 0 0-.851 2.489l.279.683c.264.645.147 1.37-.203 1.974v0C4.723 8.6 4.15 9.07 3.46 9.165l-.73.1A2 2 0 0 0 1 11.244v1.51a2 2 0 0 0 1.73 1.98l.73.1c.69.095 1.263.565 1.612 1.168v0c.35.604.467 1.329.203 1.974l-.28.683a2 2 0 0 0 .852 2.49l1.306.754a2 2 0 0 0 2.581-.508l.452-.583C10.613 20.262 11.303 20 12 20v0c.697 0 1.387.262 1.814.813l.452.583a2 2 0 0 0 2.58.507l1.307-.754a2 2 0 0 0 .852-2.489l-.28-.683c-.264-.645-.147-1.37.203-1.974v0c.349-.603.922-1.073 1.612-1.168l.73-.1a2 2 0 0 0 1.73-1.98v-1.51a2 2 0 0 0-1.73-1.98l-.73-.1c-.69-.095-1.263-.565-1.612-1.168v0c-.35-.604-.467-1.329-.203-1.974l.28-.683a2 2 0 0 0-.852-2.49l-1.306-.753a2 2 0 0 0-2.581.507l-.452.583C13.387 3.738 12.697 4 12 4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth}
      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
    />
  </svg>
);
export default SvgGear;
