import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@packages/ui/form.tsx";
import { CreateBusinessSchema } from "@packages/validation/business/management.ts";
import { isValidSubdomain } from "@packages/validation/utils.ts";
import {
	Badge,
	Button,
	Heading,
	RadioCards,
	Text,
	TextArea,
	TextField,
} from "@radix-ui/themes";
import { useDebounce } from "@uidotdev/usehooks";
import localforage from "localforage";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SecondaryLayout } from "~/components/layouts/secondary-layout.tsx";
import { api } from "~/lib/api.ts";
import {
	BUSINESS_ORG_ID_KEY,
	BUSINESS_SUBDOMAIN_KEY,
} from "~/lib/constants.ts";

export default function NewBusinessPage() {
	const navigate = useNavigate();
	const utils = api.useUtils();
	const form = useForm<CreateBusinessSchema>({
		resolver: zodResolver(CreateBusinessSchema),
	});
	const createBusiness = api.management.business.create.useMutation({
		async onSuccess(data) {
			form.reset();
			await Promise.all([
				localforage.removeItem(BUSINESS_ORG_ID_KEY),
				localforage.removeItem(BUSINESS_SUBDOMAIN_KEY),
				utils.invalidate(),
			]);
			navigate(`/biz/${data.subdomain}`);
		},
	});
	const subdomainWatcher = useDebounce(form.watch("subdomain"), 200);
	const subdomainCanBeSearched = Boolean(
		subdomainWatcher &&
			isValidSubdomain(subdomainWatcher) &&
			!form.formState.errors.subdomain,
	);
	const subdomainCheck = api.management.business.subdomainCheck.useQuery(
		subdomainWatcher,
		{
			enabled: subdomainCanBeSearched,
		},
	);
	const subdomainUnavailable =
		typeof subdomainCheck.data?.isAvailable === "boolean"
			? !subdomainCheck.data.isAvailable
			: false;

	const handleSubmit = form.handleSubmit((data) => createBusiness.mutate(data));

	return (
		<SecondaryLayout>
			<form onSubmit={handleSubmit}>
				<div className="mb-6">
					<Heading>New Business</Heading>
					<Text as="p" color="gray">
						Running a business has never been easier. Fill out the fields below
						to get started.
					</Text>
				</div>
				<div className="flex gap-6 mb-8 flex-col md:flex-row">
					<div className="flex-1 space-y-4">
						<Form.Field>
							<Form.Label htmlFor="name">Business Name</Form.Label>
							<TextField.Root
								type="text"
								placeholder="Daily Designs Co."
								id="name"
								{...form.register("name")}
							/>
							{form.formState.errors.name ? (
								<Form.Error {...form.formState.errors.name} />
							) : null}
						</Form.Field>
						<Form.Field>
							<Form.Label htmlFor="email">Business Email</Form.Label>
							<TextField.Root
								type="text"
								placeholder="dailydesignsco@gmail.com"
								id="email"
								{...form.register("email")}
							/>
							{form.formState.errors.email ? (
								<Form.Error {...form.formState.errors.email} />
							) : null}
						</Form.Field>
						<Form.Field>
							<Form.Label htmlFor="subdomain">Subdomain</Form.Label>
							<TextField.Root
								type="text"
								placeholder="dailydesignsco"
								id="subdomain"
								{...form.register("subdomain")}
							>
								<TextField.Slot side="right">
									<Badge>.mybordsy.com</Badge>
								</TextField.Slot>
							</TextField.Root>
							{subdomainCanBeSearched && subdomainUnavailable ? (
								<Form.Error
									type="subdomain-unavailable"
									message={
										subdomainCheck.isPending
											? "Checking availability..."
											: "The subdomain you're trying to use is unavailable"
									}
								/>
							) : undefined}
							{form.formState.errors.subdomain ? (
								<Form.Error {...form.formState.errors.subdomain} />
							) : null}
						</Form.Field>
						<Form.Field>
							<Form.Label htmlFor="description">Description</Form.Label>
							<TextArea
								placeholder="We design custom logos that..."
								id="description"
								{...form.register("description")}
							/>
							{form.formState.errors.description ? (
								<Form.Error {...form.formState.errors.description} />
							) : null}
						</Form.Field>
					</div>
					<div className="flex-1 space-y-4 border-t md:border-t-0 md:border-l md:pl-6 pt-6 md:pt-0">
						<Form.Field>
							<Form.Label htmlFor="platformType">
								Choose your Platform
							</Form.Label>
							<RadioCards.Root
								{...form.register("platformType")}
								columns="1"
								gap="2"
								defaultValue="productize"
							>
								<RadioCards.Item
									value="productize"
									className="!flex-col !items-start !gap-1"
								>
									<Text weight="bold">Productize</Text>
									<Text>
										Start a digital agency utilizing the productize business
										model. Manage clients and handle requests with ease.
									</Text>
								</RadioCards.Item>
								<RadioCards.Item
									disabled
									value="disabled"
									className="!flex-col !items-start !gap-1"
								>
									<Text weight="bold">
										Community
										<Badge color="gray" className="opacity-80 ml-2">
											Up Next
										</Badge>
									</Text>
									<Text>
										Create a community, manage users, and their memberships. Use
										discord or disconnect and use Bordsy&apos;s alternative.
									</Text>
								</RadioCards.Item>
							</RadioCards.Root>
							<div className="px-2 pt-2">
								<Text size="2">
									We&apos;re creating a plethora of platforms for you to start
									and manage your business, no matter the needs. If you&apos;re
									a top notch engineer looking to build something crazy,{" "}
									<a
										href="https://x.com/trybordsy"
										className="underline whitespace-nowrap"
									>
										DM us on X!
									</a>
								</Text>
							</div>
						</Form.Field>
					</div>
				</div>
				<Button type="submit" loading={createBusiness.isPending}>
					Create Business
				</Button>
			</form>
		</SecondaryLayout>
	);
}
