import ChevronRight from "@packages/ui/icons/line/arrows/chevron-right.tsx";
import { Avatar, Button, Heading, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { SecondaryLayout } from "~/components/layouts/secondary-layout.tsx";
import { api } from "~/lib/api.ts";

export default function HomePage() {
	const [businesses] = api.management.business.list.useSuspenseQuery();
	const [invites] = api.management.listInvites.useSuspenseQuery();
	const decideInvite = api.management.decideInvite.useMutation();

	return (
		<SecondaryLayout>
			<header className="flex items-center justify-between mb-6">
				<Heading as="h1">Your Businesses</Heading>
				<Button asChild variant="soft">
					<Link to="/new-business">New Business</Link>
				</Button>
			</header>
			{businesses.length === 0 && (
				<Text size="2" className="text-center">
					You don&apos;t have any businesses yet. Create a new business to get
					started.
				</Text>
			)}
			{businesses.map((business) => (
				<Link
					key={business.id}
					to={`/biz/${business.subdomain}`}
					className="flex gap-2.5 items-center hover:bg-black/5 p-2 rounded-[6px] group"
				>
					<Avatar
						fallback={business.name[0]}
						src={business.logoUrl ?? undefined}
						size="1"
						radius="full"
					/>
					<Text truncate className="flex-1" weight="medium" size="2">
						{business.name}
					</Text>
					<ChevronRight
						height={18}
						width={18}
						strokeWidth={2}
						className="opacity-0 group-hover:opacity-100"
					/>
				</Link>
			))}
			{invites.length > 0 && (
				<>
					<Heading as="h2" size="5" weight="medium" className="my-4">
						Invites
					</Heading>
					{invites.map((invite) => (
						<div
							key={invite.id}
							className="flex gap-2.5 items-center hover:bg-black/5 p-2 rounded-[6px] group"
						>
							<Avatar
								fallback={invite.businessName[0]}
								src={invite.businessLogoUrl ?? undefined}
								size="1"
								radius="full"
							/>
							<Text truncate className="flex-1" weight="medium" size="2">
								{invite.businessName}
							</Text>
							<div className="flex items-center gap-2.5">
								<Button
									onClick={() =>
										decideInvite.mutate({
											inviteId: invite.id,
											decision: "accept",
										})
									}
									variant="soft"
									className="mx-1"
									size="1"
								>
									Accept
								</Button>
								<Button
									onClick={() =>
										decideInvite.mutate({
											inviteId: invite.id,
											decision: "reject",
										})
									}
									variant="soft"
									className="mx-1"
									size="1"
								>
									Decline
								</Button>
							</div>
						</div>
					))}
				</>
			)}
		</SecondaryLayout>
	);
}
