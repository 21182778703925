import type { SVGProps } from "react";

const SidebarLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 3.5H16C16.2761 3.5 16.5 3.72386 16.5 4V14C16.5 14.2761 16.2761 14.5 16 14.5H5.75V3.5ZM4.25 3.5H2C1.72386 3.5 1.5 3.72386 1.5 4V14C1.5 14.2761 1.72386 14.5 2 14.5H4.25V3.5ZM0 4C0 2.89543 0.895431 2 2 2H16C17.1046 2 18 2.89543 18 4V14C18 15.1046 17.1046 16 16 16H2C0.89543 16 0 15.1046 0 14V4Z"
        fill="currentColor"
        strokeWidth={props.strokeWidth}
      />
      <path
        d="M12 6.5L14.5 8.99997M14.5 8.99997L12 11.5M14.5 8.99997H8"
        stroke="currentColor"
        strokeWidth={props.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SidebarLeft;
