import "~/lib/sentry.ts";

import "./styles.css";
import "@fontsource/inter/latin-400.css";
import "@fontsource/inter/latin-500.css";
import "@fontsource/inter/latin-600.css";
import "@radix-ui/themes/styles.css";
import "react-day-picker/dist/style.css";

import AlertCircle from "@packages/ui/icons/line/alerts/alert-circle.tsx";
import { TooltipProvider } from "@packages/ui/tooltip.tsx";
import { Theme as RadixThemeProvider } from "@radix-ui/themes";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "sonner";
import App from "~/App.tsx";
import { api, apiClient } from "~/lib/api.ts";
import { queryClient } from "~/lib/query.ts";

const root = document.getElementById("root");

if (!root) {
	throw new Error("No root element found");
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<RadixThemeProvider accentColor="red" radius="large" appearance="inherit">
			<api.Provider client={apiClient} queryClient={queryClient}>
				<QueryClientProvider client={queryClient}>
					<TooltipProvider>
						<Toaster
							icons={{
								error: (
									<AlertCircle
										height={18}
										width={18}
										strokeWidth={2}
										className="text-red-500"
									/>
								),
							}}
						/>
						<App />
					</TooltipProvider>
				</QueryClientProvider>
			</api.Provider>
		</RadixThemeProvider>
	</React.StrictMode>,
);
