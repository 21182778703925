import { httpBatchLink, type HTTPHeaders } from "@trpc/client";
import superjson from "superjson";
import { logto } from "./logto.ts";
import {
  BUSINESS_API_IDENTIFIER,
  BUSINESS_ORG_ID_KEY,
  BUSINESS_SUBDOMAIN_KEY,
} from "./constants.ts";
import { createTRPCQueryUtils, createTRPCReact } from "@trpc/react-query";
import { queryClient } from "./query.ts";
import { dedupedClient } from "~/lib/network.ts";
import localforage from "localforage";
import type { GatewayRouter } from "#backend/gateway.ts";

/**
 * Get necessary token headers for API requests
 */
async function tokenHeaders(): Promise<HTTPHeaders> {
  if (typeof window === "undefined") return {};

  const headers: HTTPHeaders = {};
  const isBusinessLocation = location.pathname.startsWith("/biz");

  try {
    let selectedOrgId = isBusinessLocation
      ? await localforage.getItem<string>(BUSINESS_ORG_ID_KEY)
      : null;
    const subdomain = location.pathname.split("/")[2];

    if (isBusinessLocation) {
      const previousSubdomain = await localforage.getItem<string>(
        BUSINESS_SUBDOMAIN_KEY
      );

      if (previousSubdomain !== subdomain) {
        void localforage.setItem(BUSINESS_SUBDOMAIN_KEY, subdomain);
      }

      if (previousSubdomain !== subdomain || !selectedOrgId) {
        const logtoToken = await logto.getAccessToken(BUSINESS_API_IDENTIFIER);
        const businessResponse = await dedupedClient.fetch(
          `/api/management/trpc/business.retrieve?input=${encodeURIComponent(
            JSON.stringify({ json: { subdomain } })
          )}`,
          {
            headers: {
              Authorization: `Bearer ${logtoToken}`,
            },
          }
        );
        const businessData = await businessResponse.json();
        const business = businessData.result.data.json;

        void localforage.setItem(BUSINESS_ORG_ID_KEY, business.organizationId);

        selectedOrgId = business.organizationId;
      }
    }

    const token = await logto.getAccessToken(
      BUSINESS_API_IDENTIFIER,
      selectedOrgId ?? undefined
    );

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    return headers;
  }

  return headers;
}

/**
 * Business Management API
 */
export const api = createTRPCReact<GatewayRouter>();

export const apiClient = api.createClient({
  links: [
    (runtime) => {
      // initialize the different links for different targets
      const servers = {
        management: httpBatchLink({
          transformer: superjson,
          url: "/api/management/trpc",
          headers: tokenHeaders,
        })(runtime),
        // managementRealtime: wsLink({
        //   transformer: superjson,
        //   client: wsClient,
        // })(runtime),
      };
      return (ctx) => {
        const { op } = ctx;
        // split the path by `.` as the first part will signify the server target name
        const pathParts = op.path.split(".");

        // first part of the query should be `server1` or `server2`
        const serverName = pathParts.shift() as string as keyof typeof servers;

        // combine the rest of the parts of the paths
        // -- this is what we're actually calling the target server with
        const path = pathParts.join(".");
        const link = servers[serverName];

        return link({
          ...ctx,
          op: {
            ...op,
            // override the target path with the prefix removed
            path,
          },
        });
      };
    },
  ],
});

export const apiQueryUtils = createTRPCQueryUtils({
  queryClient,
  client: apiClient,
});
