import ChevronDown from "@packages/ui/icons/line/arrows/chevron-down.tsx";
import ChevronRight from "@packages/ui/icons/line/arrows/chevron-right.tsx";
import { ScrollArea } from "@packages/ui/scroll-area.tsx";
import { cn } from "@packages/utils/cn.ts";
import type { BreadcrumbLinkItem } from "@packages/utils/types.ts";
import { DropdownMenu, Heading, Skeleton, Text } from "@radix-ui/themes";
import { Fragment, type ReactNode } from "react";
import { Link, type LinkProps } from "react-router-dom";
import { api } from "~/lib/api.ts";
import { WEBSITE_URL } from "~/lib/constants.ts";
import { logto } from "~/lib/logto.ts";

export function Breadcrumb({ children }: { children: ReactNode }) {
	return (
		<div className="flex items-center justify-start gap-2 text-lg">
			{Array.isArray(children)
				? children.map((child, index) => (
						// TODO: Find a better way to handle this key
						<Fragment key={`${index.toString()}`}>
							{child}
							{index !== children.length - 1 && (
								<ChevronRight
									width={18}
									height={18}
									strokeWidth={2}
									className="text-black/50 dark:text-white/50"
								/>
							)}
						</Fragment>
					))
				: children}
		</div>
	);
}

export function BreadcrumbItem(
	props: Omit<LinkProps, "to"> & { to?: LinkProps["to"] },
) {
	return (
		<Link
			className={cn(
				"flex items-center justify-start gap-2 last:font-medium",
				!props.to ? "cursor-auto" : "hover:underline",
			)}
			{...props}
			to={props.to ?? "#"}
		/>
	);
}

export function MainLayoutHeader({
	breadcrumbs,
}: { breadcrumbs: BreadcrumbLinkItem[] }) {
	const [profile] = api.management.profile.retrieve.useSuspenseQuery();

	return (
		<header className="h-[80px] flex items-center justify-between px-5">
			<Breadcrumb>
				{breadcrumbs.map((item) => (
					<BreadcrumbItem key={item.to} to={item.to}>
						{item.title}
					</BreadcrumbItem>
				))}
			</Breadcrumb>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger>
					<button
						type="button"
						className="inline-flex rounded-full border border-black/10 dark:border-white/10 bg-white/30 dark:bg-black/30 h-10 px-1 pr-2.5 items-center justify-between gap-5 shadow-float"
					>
						<div className="flex items-center justify-start gap-2.5">
							<div className="w-8 h-8 rounded-full bg-black/10 dark:bg-white/10 border border-black/10 dark:border-white/10" />
							<span className="font-semibold text-sm text-black dark:text-white">
								{profile.firstName && profile.lastName ? (
									<>
										{profile.firstName} {profile.lastName[0]}.
									</>
								) : (
									"Profile Not Setup"
								)}
							</span>
						</div>
						<ChevronDown
							height={18}
							width={18}
							strokeWidth={2}
							className="stroke-black/50 dark:text-white/50"
						/>
					</button>
				</DropdownMenu.Trigger>
				<DropdownMenu.Content side="bottom" align="end">
					<DropdownMenu.Item
						className="hover:bg-black/5 dark:hover:bg-white/5 hover:text-black dark:hover:text-white"
						asChild
					>
						<Link to="/profile">Account Settings</Link>
					</DropdownMenu.Item>
					<DropdownMenu.Item
						className="hover:bg-black/5 dark:hover:bg-white/5 hover:text-black dark:hover:text-white"
						onClick={() => logto.signOut(WEBSITE_URL)}
					>
						Sign Out
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Root>
		</header>
	);
}

export function MainLayoutContent({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) {
	return (
		<ScrollArea
			className={cn(
				"bg-surface flex-1 rounded-tl-[20px] border-t border-l border-black/10 dark:border-white/10 shadow-float dark:shadow-none",
				className,
			)}
		>
			<main className="p-[40px]">{children}</main>
		</ScrollArea>
	);
}

export function MainLayoutContentPending({
	breadcrumbs,
}: { breadcrumbs: BreadcrumbLinkItem[] }) {
	return (
		<>
			<MainLayoutHeader breadcrumbs={breadcrumbs} />
			<MainLayoutContent>
				<div className="mb-6">
					<Heading>
						<Skeleton>Some Heading</Skeleton>
					</Heading>
					<Text as="p" color="gray">
						<Skeleton>
							Some descriptive text to describe the page you&apos;re on
						</Skeleton>
					</Text>
				</div>
				<div className="flex flex-col gap-6">
					<div>
						<Heading size="4" className="mb-4">
							<Skeleton>Some Other Heading</Skeleton>
						</Heading>
						<Skeleton>
							<div className="h-[150px]" />
						</Skeleton>
					</div>
					<Skeleton>
						<div className="h-[250px]" />
					</Skeleton>
					<Skeleton>
						<div className="h-[250px]" />
					</Skeleton>
				</div>
			</MainLayoutContent>
		</>
	);
}
